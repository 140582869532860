import Vue from "vue"
import Vuex from "vuex"

import purchases from "./modules/purchases"
import purchaseTypes from "./modules/purchaseTypes"
import vendors from "./modules/vendors"
import user from "./modules/user"

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        purchases,
        purchaseTypes,
        vendors,
        user,
    },
})

// Allow hot reloading of modules with webpack
if (module.hot) {
    module.hot.accept(
        ["./modules/purchases", "./modules/purchaseTypes", "./modules/vendors", "./modules/user"],
        () => {
            store.hotUpdate({
                modules: {
                    purchases: require("./modules/purchases").default,
                    purchaseTypes: require("./modules/purchaseTypes").default,
                    vendors: require("./modules/vendors").default,
                    user: require("./modules/user").default,
                },
            })
        },
    )
}

export default store
