<template>
  <div id="app" class="d-flex flex-column" style="height: 100vh">
    <Header />
    <div style="height: 100vh">
      <Navigation />
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import Header from "./components/Header"
import Navigation from "./components/Navigation"

export default {
  components: {
    Header,
    Navigation,
  },
  async beforeMount() {
    //await this.$auth.login()
  },
}
</script>

<style lang="postcss" scoped>
@import "./css/aggrid.css";
@import "./css/general.css";
</style>
