import Vue from "vue"
import jscookie from "js-cookie"
import router from "@/router"

export const API_JWT_AUTH = "renewit_jwt_auth"

const state = {
    token: jscookie.get(API_JWT_AUTH),
    roles: null,
    useLocalTokenAPI: false,
}

export const mutations = {
    setToken(state, payload) {
        console.log("Setting Token")

        if (payload) {
            jscookie.set(API_JWT_AUTH, payload, { expires: 1 })
        } else {
            jscookie.remove(API_JWT_AUTH)
        }

        Vue.set(state, "token", payload)
    },

    setRoles(state, payload) {
        console.log("Setting Roles")

        if (payload) {
            Vue.set(state, "roles", payload)
        } else {
            Vue.delete(state, "roles")
        }
    },
}

export const actions = {
    async getToken({ commit, getters }, payload) {
        console.log("Get Token")
        try {
            const response = await fetch(getters.jwtUrl, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify(payload),
            })

            if (!response.ok) {
                router.push({ name: "ServiceDown" })
                return null
            }

            const data = await response.json()
            commit("setToken", data.token)
            return data.token
        } catch (error) {
            console.error("Error fetching token:", error)
            router.push({ name: "ServiceDown" })
            return null
        }
    },

    async getRoles({ commit, getters }, payload) {
        console.log("Get Roles")
        try {
            const response = await fetch(getters.doggoRolesUrl, {
                headers: getters.myHeaders,
                method: "POST",
                body: JSON.stringify(payload),
            })

            if (!response.ok) {
                router.push({ name: "ServiceDown" })
                return null
            }

            const data = await response.json()
            console.log("Roles ", data)
            commit("setRoles", data)
            return data
        } catch (error) {
            console.error("Error fetching roles:", error)
            router.push({ name: "ServiceDown" })
            return null
        }
    },
}

const getters = {
    isUser: state => (state.roles && state.roles.includes("user")) || false,

    jwtUrl: state =>
        state.useLocalTokenAPI
            ? "https://localhost:7225/getDoggoJwt"
            : "https://api.ba.arizona.edu/common/getDoggoJwt",

    doggoRolesUrl: state =>
        state.useLocalTokenAPI
            ? "https://localhost:7225/getDoggoRoles"
            : "https://api.ba.arizona.edu/common/getDoggoRoles",

    myHeaders: state => {
        const headers = new Headers()

        headers.append("Authorization", `Bearer ${state.token}`)
        headers.append("Accept", "application/json; indent=4")
        headers.append("Content-Type", "application/json")

        return headers
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
