<template>
  <div class="m-1 flex-bigly">
    <div
      class="d-flex flex-row justify-content-between align-items-center pb-1"
    >
      <div>
        <icon-button
          label="Create"
          type="primary"
          class="mr-1"
          icon="cross"
          data-cy="createPurchase"
          @click="openCreateModal"
        />
        <icon-button
          label="Edit"
          icon="pencil"
          type="primary-light"
          :disabled="!selectionExists"
          data-cy="editPurchase"
          @click="openEditModal"
        />
      </div>
      <div class="d-flex flex-row">
        <input-box
          id="fuzzySearch"
          v-model="quickFilter"
          class="my-0"
          type="search"
          placeholder="Search all..."
          addon="start"
          data-cy="fuzzySearch"
        >
          <icon slot="addon-start" icon="search"></icon>
        </input-box>
        <icon-button
          id="refreshButton"
          title="Refresh"
          icon="refresh-ccw"
          class="ml-1 text-dark"
          type="secondary"
          outline
          data-cy="refreshButton"
          @click="refreshGrid"
        />
      </div>
    </div>
    <grid-tools
      :filter-action="toggleFilterPanel"
      :filter-active="showFilters"
      :column-action="toggleColumnSelectPanel"
      :column-active="showColumnSelect"
      :grid-options="gridOptions"
      @refreshGrid="refreshGrid"
      @clearFilters="$refs.filters.clearAll()"
      @showDefaultColumns="showDefaultColumns"
      @showAllColumns="showAllColumns"
    />
    <div class="flex-bigly-row grid-wrapper">
      <filter-panel
        v-if="gridApi"
        v-show="showFilters"
        ref="filters"
        class="panel panel-filters"
        :column-defs="gridOptions.columnDefs"
        :row-data="items"
        :grid-api="gridApi"
      />
      <ag-grid-vue
        ref="grid"
        class="grid-flex ag-theme-balham"
        :row-data="items"
        :grid-options="gridOptions"
        @grid-ready="onGridReady"
        @row-double-clicked="openEditModal"
      />
      <columns-panel
        v-if="columnApi"
        v-show="showColumnSelect"
        ref="columns"
        class="panel panel-columns"
        :column-api="columnApi"
      />
    </div>
    <modal
      id="purchaseModal"
      ref="purchaseModal"
      v-model="modalVisible"
      :title="modalTitle"
      size="custom"
      :max-width="800"
      @hidden="onModalHidden"
    >
      <template #default class="overflow-wrapper">
        <div class="overflow-hide-x">
          <!-- Purchase Modal -->
          <transition name="slide-left">
            <div v-if="showPurchaseForm">
              <!-- Purchase Form -->
              <collapse-panel title="Purchase" class="my-2">
                <div slot="content">
                  <div data-cy="purchasesForm">
                    <purchases-form
                      v-if="modalVisible === true"
                      id="purchasesForm"
                    />
                  </div>
                  <div class="d-flex justify-content-end">
                    <icon-button
                      id="clearPurchase"
                      label="Clear"
                      type="warning"
                      class="ml-1"
                      icon="wind"
                      outline
                      @click="clearItem"
                    />
                  </div>
                </div>
              </collapse-panel>
              <!-- Line Items Forms -->
              <p class="h4 mt-2">Line Items</p>
              <div
                v-for="item in activeLineItems"
                :key="item.uuid ? item.uuid : 1"
              >
                <collapse-panel
                  class="my-2 line-item-panel"
                  :title="lineItemPanelName(item.purchase_type)"
                >
                  <div slot="summary">
                    <div class="d-flex align-items-start justify-content-start">
                      <p class="mb-0 ml-4">
                        <strong>Quantity: </strong>{{ item.quantity }}
                      </p>
                      <p class="mb-0 ml-4">
                        <strong>Total: </strong
                        >{{ item.line_item_total | currency }}
                      </p>
                    </div>
                  </div>
                  <div slot="content">
                    <div class="mb-2">
                      <line-item-form :id="item.uuid" @active="setActiveLI" />
                    </div>
                    <div
                      class="d-flex justify-content-end line-item-attributes"
                    >
                      <div class="card mr-auto total-container">
                        <div
                          class="card-body py-0 d-flex justify-content-start"
                        >
                          <p class="align-self-center mb-0 font-weight-bold">
                            Line Item Total:
                          </p>
                          <p
                            class="align-self-center mb-0 ml-2"
                            data-cy="lineItemTotal"
                          >
                            {{ item.line_item_total | currency }}
                          </p>
                        </div>
                      </div>
                      <div class="action-container d-flex">
                        <delete-button
                          ref="pop"
                          @input="removeLineItem(item.uuid)"
                        />
                        <icon-button
                          label="Clear"
                          type="warning"
                          class="ml-1"
                          icon="wind"
                          outline
                          data-cy="clearLineitem"
                          @click="clearLineItemForm(item.uuid)"
                        />
                      </div>
                    </div>
                  </div>
                </collapse-panel>
              </div>
              <div class="d-flex justify-content-end">
                <div class="card mr-auto">
                  <div class="card-body py-0 d-flex justify-content-start">
                    <p class="align-self-center mb-0 font-weight-bold">
                      Purchase Total:
                    </p>
                    <p
                      class="align-self-center mb-0 ml-2"
                      data-cy="purchaseTotal"
                    >
                      {{ purchaseTotal | currency }}
                    </p>
                  </div>
                </div>
                <icon-button
                  class="align-self-baseline"
                  label="New Line Item"
                  type="primary"
                  icon="plus-circle"
                  data-cy="newLineItem"
                  @click="addLI"
                />
              </div>
              <div v-if="activeItem.user" class="mb-0 mt-2">
                <i>Last modified by </i><b>{{ activeItem.user }}</b
                ><i> on {{ modifiedDate }}</i>
              </div>
              <alert
                v-if="errors.server"
                id="alert-purchases"
                class="mt-2"
                type="danger"
                :message="errors.server"
              />
            </div>
          </transition>
          <!-- Vendors Modal -->
          <transition name="slide-from-right">
            <div v-if="showVendorForm" style="width: 100%">
              <vendors-form id="vendorForm" class="mx-2"> </vendors-form>
            </div>
          </transition>
          <!-- Purchase Types Modal -->
          <transition name="slide-from-right">
            <div v-if="showPurchaseTypeForm" style="width: 100%">
              <purchase-types-form id="purchaseTypeForm" class="mx-2">
              </purchase-types-form>
            </div>
          </transition>
        </div>
      </template>
      <template slot="footer">
        <!-- Purchases Modal Commands -->
        <div v-if="showPurchaseForm">
          <icon-button
            label="Cancel"
            type="accent"
            outline
            icon="x"
            data-cy="cancelPurchaseForm"
            @click="closeModal"
          />
          <icon-button
            class="saveAndCopy mx-1"
            label="Save and Copy"
            type="primary"
            icon="save"
            :disabled="disableSave"
            outline
            data-cy="saveAndCopyPurchaseForm"
            @click="modalSaveAndCopy"
          />
          <icon-button
            class="save"
            label="Save"
            type="primary"
            icon="save"
            :disabled="disableSave"
            data-cy="savePurchasesForm"
            @click="modalSave"
          />
        </div>
        <!-- Vendors Modal Commands -->
        <div v-if="showVendorForm">
          <icon-button
            label="Clear"
            type="warning"
            class="mr-1"
            icon="wind"
            outline
            data-cy="clearVendorForm"
            @click="clearVendorForm"
          />
          <icon-button
            label="Cancel"
            type="accent"
            outline
            class="mr-1"
            icon="x"
            data-cy="cancelVendorForm"
            @click="setShowVendorForm(false)"
          />
          <icon-button
            label="Add"
            type="primary"
            class="mr-1"
            icon="plus-circle"
            data-cy="addVendorForm"
            @click="createVendor"
          />
        </div>
        <!-- Purchase Types Modal Commands -->
        <div v-if="showPurchaseTypeForm">
          <icon-button
            label="Clear"
            type="warning"
            class="mr-1"
            icon="wind"
            outline
            data-cy="clearPurchasetypeForm"
            @click="clearPurchaseTypeForm"
          />
          <icon-button
            label="Cancel"
            class="mr-1"
            type="accent"
            outline
            icon="x"
            data-cy="cancelPurchasetypeForm"
            @click="setShowPurchaseTypeForm(false)"
          />
          <icon-button
            label="Add"
            type="primary"
            class="mr-1"
            icon="plus-circle"
            data-cy="addPurchasetypeForm"
            @click="createPurchaseType"
          />
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
// Components
import FilterPanel from "@/components/FilterPanel.vue"
import { AgGridVue } from "ag-grid-vue"
import ColumnsPanel from "@/components/ColumnsPanel.vue"
import GridTools from "@/components/GridTools.vue"
import CollapsePanel from "@/components/CollapsePanel.vue"
import IconButton from "@/components/IconButton.vue"
import Icon from "@/components/Icon.vue"
import InputBox from "@/components/InputBox.vue"
import Modal from "@/components/Modal.vue"
import DeleteButton from "@/components/DeleteButton.vue"
import Alert from "@/components/Alert.vue"
// Forms
import LineItemForm from "@/components/LineItemForm.vue"
import PurchasesForm from "@/components/PurchasesForm.vue"
import VendorsForm from "@/components/VendorsForm.vue"
import PurchaseTypesForm from "@/components/PurchaseTypesForm.vue"
// Helpers, Functionality
import Uuid from "uuid"
import { mapActions, mapGetters } from "vuex"
import useGrid from "@/composables/useGrid"

export default {
  components: {
    PurchasesForm,
    LineItemForm,
    VendorsForm,
    PurchaseTypesForm,
    FilterPanel,
    ColumnsPanel,
    AgGridVue,
    GridTools,
    CollapsePanel,
    IconButton,
    Icon,
    InputBox,
    Modal,
    DeleteButton,
    Alert,
  },
  beforeRouteLeave(to, from, next) {
    this.clearItem()
    next()
  },
  setup(_props) {
    const gridConfig = {
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalRowCountComponent",
            align: "left",
            key: "totalRowComponent",
          },
          { statusPanel: "agFilteredRowCountComponent", align: "left" },
          {
            statusPanel: "agAggregationComponent",
            align: "right",
            statusPanelParams: { aggFuncs: ["min", "max", "avg", "sum"] },
          },
        ],
      },
      columnDefs: [
        {
          headerName: "Vendor",
          field: "vendor",
          hide: false,
          menuTabs: ["generalMenuTab"],
        },
        {
          headerName: "Purchase Types",
          field: "purchase_types",
          hide: false,
          menuTabs: ["generalMenuTab"],
        },
        {
          headerName: "Purchase Description",
          field: "purchase_description",
          hide: false,
          menuTabs: ["generalMenuTab"],
        },
        {
          headerName: "Status",
          field: "status",
          hide: false,
          menuTabs: ["generalMenuTab"],
        },
        {
          headerName: "FSO Contact Unit",
          field: "fso_contact_unit",
          hide: false,
          menuTabs: ["generalMenuTab"],
        },
        {
          headerName: "FSO Contact Name",
          field: "fso_contact_name",
          hide: true,
          menuTabs: ["generalMenuTab"],
        },
        {
          headerName: "Purchase Total",
          field: "purchase_total",
          hide: false,
          menuTabs: ["generalMenuTab"],
          comparator: function (firstVal, secondVal) {
            const first = Number(firstVal)
            const second = Number(secondVal)
            return first - second
          },
          cellRenderer: "currencyCellRenderer",
        },
        {
          headerName: "Expiration Date",
          field: "expiration_date",
          hide: false,
          cellRenderer: "dateCellRenderer",
          menuTabs: ["generalMenuTab"],
        },
        {
          headerName: "Edoc Number",
          field: "edoc_number",
          hide: false,
          menuTabs: ["generalMenuTab"],
        },
        {
          headerName: "Purchase Date",
          field: "purchase_date",
          hide: true,
          menuTabs: ["generalMenuTab"],
        },
        {
          headerName: "Date Renewed",
          field: "date_renewed",
          hide: true,
          cellRenderer: "dateCellRenderer",
          menuTabs: ["generalMenuTab"],
        },
        {
          headerName: "Notes",
          field: "notes",
          hide: true,
          menuTabs: ["generalMenuTab"],
        },
        {
          headerName: "Last Modified By",
          field: "user",
          hide: true,
          menuTabs: ["generalMenuTab"],
        },
        {
          headerName: "Last Modified Date",
          field: "modified",
          hide: true,
          cellRenderer: "dateTimeCellRenderer",
          menuTabs: ["generalMenuTab"],
        },
      ],
    }
    const gridDefaults = {
      sort: [{ colId: "expiration_date", sort: "asc" }],
      filter: {
        date_renewed: {
          filterType: "set",
          values: [null],
        },
      },
    }
    const {
      modal,
      gridApi,
      columnApi,
      gridOptions,
      quickFilter,
      showColumnSelect,
      showFilters,
      selectionExists,
      setGridDefaults,
      showDefaultColumns,
      showAllColumns,
      toggleFilterPanel,
      toggleColumnSelectPanel,
    } = useGrid(gridConfig, gridDefaults)
    return {
      modal,
      gridApi,
      columnApi,
      gridOptions,
      quickFilter,
      showColumnSelect,
      showFilters,
      selectionExists,
      setGridDefaults,
      showDefaultColumns,
      showAllColumns,
      toggleFilterPanel,
      toggleColumnSelectPanel,
    }
  },
  data() {
    return {
      disableSave: false,
      modalVisible: false,
      // tells Vue to ALWAYS re-render the form components so there is not data bleed
      renderForm: false,
      // For tracking which Line Item we are adding a purchase Type to
      activeLI: undefined,
    }
  },
  computed: {
    ...mapGetters("purchases", [
      "errors",
      "items",
      "activeItem",
      "activeLineItems",
      "showVendorForm",
      "showPurchaseTypeForm",
    ]),
    purchaseTotal: function () {
      let total = 0
      for (const li of this.$store.state.purchases.activeItem
        .lineitem_purchase) {
        const itemtot = parseFloat(li.line_item_total)
        total += parseFloat(itemtot)
      }
      return total.toFixed(2)
    },
    modifiedDate: function () {
      //Parses activeItem.modified into something human readable
      const m = this.activeItem.modified.split("-")
      const result =
        m[1] + "-" + m[2].slice(0, 2) + "-" + m[0] + " at " + m[2].slice(3, 8)
      return result
    },
    showPurchaseForm: function () {
      return !this.showVendorForm && !this.showPurchaseTypeForm
    },
    modalTitle: function () {
      if (this.showVendorForm) {
        return "Create New Vendor"
      } else if (this.showPurchaseTypeForm) {
        return "Create New Purchase Type"
      } else if (this.modal.type === "create") {
        return "Create New Purchase"
      } else if (this.modal.type === "edit") {
        return "Edit Purchase"
      } else {
        return "Modal Title"
      }
    },
  },
  watch: {
    purchaseTotal: function (value) {
      this.setPurchaseTotal(value)
    },
  },
  methods: {
    ...mapActions("purchases", [
      "setItem",
      "setItemField",
      "clearItem",
      "clearErrors",
      "resetActiveItem",
      "addLI",
      "removeLI",
      "clearLI",
      "setLIField",
      "setShowVendorForm",
      "setShowPurchaseTypeForm",
      "post",
      "put",
      "fetchAll",
      "setPurchaseTotal",
      "delete",
    ]),
    ...mapActions("vendors", {
      clearVendor: "clearItem",
      postVendor: "post",
    }),
    ...mapActions("purchaseTypes", {
      clearPurchaseType: "clearItem",
      postPurchaseType: "post",
    }),
    openCreateModal() {
      this.resetActiveItem()
      this.modal.type = "create"
      this.modal.action = "post"
      this.modalVisible = true
      this.setShowVendorForm(false)
      this.setShowPurchaseTypeForm(false)
      this.$refs.purchaseModal.show()
    },
    openEditModal() {
      this.setItem(this.gridApi.getSelectedRows()[0])
      this.modalVisible = true
      this.modal.type = "edit"
      this.modal.action = "put"
      this.setShowVendorForm(false)
      this.setShowPurchaseTypeForm(false)
      this.$refs.purchaseModal.show()
    },
    closeModal() {
      this.modal.type = ""
      this.resetActiveItem()
      this.modalVisible = false
    },
    async modalSave() {
      this.disableSave = true
      try {
        let response =
          this.modal.action === "post" ? await this.post() : await this.put()
        if (
          response !== null &&
          !Object.keys(this.errors.lineitem_purchase).length &&
          Object.keys(this.errors).length == 1
        ) {
          this.closeModal()
          this.refreshGrid()
        }
      } catch (error) {
        console.error("something went wrong on Save:")
        console.error(error)
      }
      this.disableSave = false
    },
    async modalSaveAndCopy() {
      this.disableSave = true
      try {
        let response =
          this.modal.action === "post" ? await this.post() : await this.put()
        if (
          response !== null &&
          !Object.keys(this.errors.lineitem_purchase).length &&
          Object.keys(this.errors).length == 1
        ) {
          // Deep Copy the current item, modify it's contents
          var savedState = JSON.parse(JSON.stringify(this.activeItem))
          savedState.id = undefined
          savedState.purchase_date = ""
          savedState.status = ""
          savedState.edoc_number = ""
          savedState.expiration_date = ""
          savedState.date_renewed = ""
          for (var li in savedState.lineitem_purchase) {
            savedState.lineitem_purchase[li].id = undefined
            savedState.lineitem_purchase[li].uuid = Uuid()
            savedState.lineitem_purchase[li].purchase = undefined
            savedState.lineitem_purchase[li].purchase_id = undefined
          }
          await this.closeModal()
          this.openCreateModal()
          this.setItem(savedState)
        }
      } catch (error) {
        console.error("Something went wrong on Save and Copy:")
        console.error(error)
      }
      this.disableSave = false
    },
    onModalHidden() {
      this.renderForm = !this.renderForm
    },
    clearLineItemForm(id) {
      this.activeLineItems.forEach((li, index) => {
        if (li.uuid === id) {
          this.clearLI(index)
        }
      })
    },
    clearVendorForm() {
      this.clearVendor()
    },
    clearPurchaseTypeForm() {
      this.clearPurchaseType()
    },
    setActiveLI(id) {
      // Set the active Line Item's index so we can give it new purchase types
      this.activeLineItems.forEach((li, index) => {
        if (li.uuid === id) {
          this.activeLI = index
        }
      })
    },
    lineItemPanelName(val) {
      // Displays a temporary name if there is no Purchase Type selected yet.
      if (val === "") {
        return "New Line Item"
      } else {
        return val
      }
    },
    removeLineItem(id) {
      // Remove the line item by index
      this.activeLineItems.forEach((li, index) => {
        if (li.uuid === id) {
          if (li.id === undefined) {
            this.removeLI(index)
          } else {
            this.delete(index)
            this.removeLI(index)
          }
        }
      })
    },
    createVendor() {
      const vm = this
      vm.postVendor().then((result) => {
        vm.setItemField({
          field: "vendor",
          payload: result.data.name,
        })
        vm.setShowVendorForm(false)
      })
    },
    createPurchaseType() {
      const vm = this
      vm.postPurchaseType().then((result) => {
        vm.setLIField({
          index: this.activeLI,
          field: "purchase_type",
          payload: result.data.name,
        })
        vm.setShowPurchaseTypeForm(false)
      })
    },
    async refreshGrid() {
      this.gridApi.showLoadingOverlay()
      try {
        await this.fetchAll()
        this.setGridDefaults()
        this.showDefaultColumns()
        this.$refs.filters.init()
      } catch (error) {
        console.error("something went wrong on Refresh: ")
        console.error(error)
      }
      this.gridApi.hideOverlay()
    },
    async onGridReady() {
      await this.$nextTick() // await existence of filters ref
      this.refreshGrid()
    },
  },
}
</script>

<style lang="postcss" scoped>
.line-item-attributes {
  flex-direction: row;
}
@media screen and (max-width: 576px) {
  .line-item-attributes {
    flex-direction: column;
  }

  .line-item-attributes .total-container {
    width: 100%;
    height: 40px;
    margin: 0.25em 0;
  }

  .line-item-attributes .action-container {
    margin: 0.25em 0;
    display: flex;
    justify-content: flex-end;
  }
}

/* Modal Transistion Effects */
.overflow-wrapper {
  overflow-y: visible;
}

.overflow-hide-x {
  position: relative;
  overflow-x: hidden;
}

.slide-left-enter-active,
.slide-left-leave-active {
  transition: transform 0.5s;
}
.slide-left-enter,
.slide-left-leave-to {
  transform: translate(-100%, 0);
}

.slide-from-right-enter-active,
.slide-from-right-leave-active {
  transition: transform 0.5s;
}
.slide-from-right-enter,
.slide-from-right-leave-to {
  position: absolute;
  top: 0;
  transform: translate(100%, 0);
}
.slide-from-right-enter-to {
  position: absolute;
  top: 0;
}

/* Make the 'clear' animations a little shorter than prescribed */
#purchasesForm {
  animation-duration: 0.75s;
}

.delete-confirm {
  margin-left: 0.125em;
  margin-right: 0.125em;
  z-index: 10;
  border: none;
}
.delete-confirm .drop-target {
  height: 100% !important;
}
</style>
