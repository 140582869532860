import { authHeaderInterceptor } from "@uarizona-fnsv/vue-doggo"
import axios from "axios"
import store from "@/store"

axios.defaults.xsrfCookieName = "csrftoken"
axios.defaults.xsrfHeaderName = "X-CSRFToken"

export default () => {
  const api = axios.create({
    baseURL:
      process.env.NODE_ENV === "development"
        ? "http://localhost:8000/api"
        : "/api/",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
  //api.interceptors.request.use(authHeaderInterceptor)
  api.interceptors.request.use(
    config => {
      const token = store.state.user.token // Get token from Vuex store
  
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`
      }
  
      return config
    },
    error => {
      return Promise.reject(error)
    },
  )
  return api
}
