<template>
  <nav class="navbar navbar-expand navbar-dark bg-accent p-2">
    <div class="container align-ite ml-center">
      <a
        class="navbar-brand"
        href="http://www.arizona.edu"
        title="The University of Arizona"
      >
        <img
          style="width: 250px"
          alt="The University of Arizona"
          src="https://assets.cdn.fso.arizona.edu/ua_wordmark_line_logo_white_rgb.svg"
        />
      </a>
      <div
        v-if="currentEnvironment.name !== 'Production'"
        :class="`ml-auto badge badge-pill bg-${currentEnvironment.color}`"
      >
        {{ currentEnvironment.name }}
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  computed: {
    currentEnvironment() {
      const hostname = window.location.hostname
      if (hostname === "localhost") {
        return { name: "Local", color: "primary" }
      } else if (hostname.match("-dev")) {
        return { name: "Development", color: "primary" }
      } else if (hostname.match("-stage")) {
        return { name: "Stage", color: "warning" }
      } else {
        return { name: "Production", color: "accent" }
      }
    },
  },
}
</script>
