<template>
  <div
    v-show="isShown"
    class="alert d-flex align-items-center"
    :class="['alert-' + type, { 'alert-dismissible': dismissible }]"
    role="alert"
  >
    <button
      v-if="dismissible"
      type="button"
      class="close ml-2"
      aria-label="Close"
      @click="hide"
    >
      <span class="sr-only">Close</span>
    </button>
    <icon v-if="icon" :icon="iconForType" class="mr-2"></icon>
    <slot name="content">
      <span class="font-weight-bold">{{ notice }} </span
      ><span class="font-weight-normal">{{ message }}</span>
    </slot>
  </div>
</template>

<script>
import Icon from "./Icon"

export default {
  name: "Alert",
  components: {
    Icon,
  },
  props: {
    type: {
      type: String,
      default: "info",
      validator: function (value) {
        return ["success", "warning", "danger", "info"].indexOf(value) > -1
      },
    },
    notice: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
    dismissible: {
      type: Boolean,
      default: false,
    },
    timeout: {
      type: Number,
      default: null,
    },
    icon: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isShown: true,
      dismiss: undefined,
    }
  },
  computed: {
    iconForType: function () {
      if (this.type === "success") {
        return "check-circle"
      } else if (this.type === "warning") {
        return "alert-circle"
      } else if (this.type === "danger") {
        return "x-circle"
      } else {
        return "info"
      }
    },
  },
  updated() {
    this.timeout ? this.setDismiss() : null
  },
  methods: {
    /**
     * Hide the alert
     */
    hide() {
      this.isShown = false
      this.$emit("hidden")
    },
    /**
     * Show the alert
     */
    show() {
      this.isShown = true
      this.$emit("shown")
    },
    /**
     * Toggle visibility of the alert
     */
    toggle() {
      this.isShown ? this.hide() : this.show()
    },
    /**
     * Set a timer to hide the alert after a specified number of milliseconds
     * @prop {Number} timeout ms after which to hide the alert, overriden by the `timeout` property
     * @returns {Object} the `setTimeout` function callback
     */
    setDismiss(timeout) {
      let vm = this
      this.dismiss = setTimeout(() => {
        vm.hide()
      }, vm.timeout || timeout)
      return this.dismiss
    },
    /**
     * Clear a timer, preventing any timed close defined in `timeout` or via the `setDismiss()` method
     */
    clearDismiss() {
      clearTimeout(this.dismiss)
      this.dismiss = undefined
    },
  },
}
</script>
