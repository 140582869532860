import VueRouter from "vue-router"

import Vendors from "@/pages/Vendors.vue"
import PurchaseTypes from "@/pages/PurchaseTypes.vue"
import LineItems from "@/pages/LineItems.vue"
import Purchases from "@/pages/Purchases.vue"
import store from "@/store"

// Assign views to routes here
const routes = [
  { path: "/", redirect: "/purchases" },
  { path: "/purchases", component: Purchases, name: "purchases" },
  { path: "/vendors", component: Vendors, name: "vendors" },
  {
    path: "/purchase-types",
    component: PurchaseTypes,
    name: "purchase-types",
  },
  { path: "/line-items", component: LineItems, name: "line-items" },
]

const router = new VueRouter({
  routes,
})

// BEFORE EACH NAVIGATION GUARD
router.beforeEach(async (to, from, next) => {
  console.log("BeforeEach started")

  //Except for NotAuthorized
  if (to.name === "NotAuthorized") {
    return next()
  }

  const token = store.state.user.token // Ensure proper token reference

  if (!token || token === "undefined") {
    const webAuthURL = "https://webauth.arizona.edu/webauth/login?service="
    //const location = to.path
    const location = window.location.pathname
    const serviceURL = window.location.origin

    const params = new URLSearchParams(window.location.search)
    const ticket = params.get("ticket")

    if (!ticket) {
      console.log("Webauth", webAuthURL + serviceURL + location)
      console.log("ServiceURL", serviceURL)
      console.log("to.path", to.path)
      console.log("to.fullPath", to.fullPath)
      console.log("window.location.pathname", window.location.pathname)

      window.location.replace(webAuthURL + serviceURL + location)
      return
    } else {
      try {
        const newToken = await store.dispatch("user/getToken", {
          ticket,
          location,
          serviceURL,
        })

        if (!newToken) {
          console.error("Token retrieval failed")
          return next(false)
        }

        cleanUpURL() // Take the ugly ticket off the URL
      } catch (error) {
        console.error("Error fetching token:", error)
        return next(false)
      }
    }
  }

  //Load roles
  if (!store.state.user.roles) {
    await store.dispatch("user/getRoles", { app_id: process.env.VUE_APP_COGNITO_CLIENT_ID })
  }

  if (!store.getters['user/isUser']) {
    console.log("Denied")
    return next({ name: "NotAuthorized" })
  } else {
    console.log("User allowed to the requested route")
  }

  console.log("Proceeding to route")
  return next()
})

// FUNCTION TO CLEAN UP URL PARAMETERS
function cleanUpURL() {
  const url = new URL(window.location.href)
  url.search = "" // Remove query parameters
  window.history.replaceState({}, document.title, url.toString())
}

export default router
